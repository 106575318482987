<app-elearning-banner></app-elearning-banner>

<app-slogan></app-slogan>

<app-vendor-courses></app-vendor-courses>

<app-funfacts-feedback></app-funfacts-feedback>

<div class="blog-area ptb-100">
    <app-blog></app-blog>
</div>


<div class="premium-access-area ptb-100">
    <app-premium-access></app-premium-access>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
</div>
