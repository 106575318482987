import { Component, OnInit } from '@angular/core';
import { CoursesService } from 'src/app/shared/services/courses.service';

@Component({
    selector: 'app-elearning-school',
    templateUrl: './elearning-school.component.html',
    styleUrls: ['./elearning-school.component.scss']
})
export class ElearningSchoolComponent implements OnInit {

    constructor(private courseService: CoursesService) { }

    async ngOnInit(): Promise<void> {
        this.courseService.getCourses();
    }

}
