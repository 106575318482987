import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-courses-view',
  templateUrl: './courses-view.component.html',
  styleUrls: ['./courses-view.component.scss']
})
export class CoursesViewComponent implements OnInit {
  isActive:boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  showCollapse(collapseId:string) {
      let element = document.getElementById(collapseId);
      element.classList.contains('show') ? element.classList.remove('show') : element.classList.add('show');
  }

}
