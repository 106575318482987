import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import lgVideo from 'lightgallery/plugins/video';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { Course } from 'src/app/models/course';
import { CoursesService } from 'src/app/shared/services/courses.service';

@Component({
	selector: 'app-courses-details-style-one',
	templateUrl: './courses-details-style-one.component.html',
	styleUrls: ['./courses-details-style-one.component.scss']
})
export class CoursesDetailsStyleOneComponent implements OnInit {

    course_id:string;
    course:Course;

	constructor(
        private route: ActivatedRoute,
        private coursesService: CoursesService
    ) { }

	ngOnInit(): void {
        this.course_id = this.route.snapshot.paramMap.get('id');
        this.coursesService.getCoursebyId(this.course_id).subscribe(course => this.course = course);
    }

    settings = {
        counter: false,
        plugins: [lgVideo]
    };
    onBeforeSlide = (detail: BeforeSlideDetail): void => {
        const { index, prevIndex } = detail;
        console.log(index, prevIndex);
    };



}
