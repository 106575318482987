import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Course } from 'src/app/models/course';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {
  private API_URL = environment.API_URL;
  loadLevel: number = 0;
  courses: Course[];

  constructor(
      private http: HttpClient
  ) { }

  async getCourses() {
    if(this.loadLevel++ < 1 || !this.courses)
    {
        this.courses = (await this.http.get(`${this.API_URL}/courses`).toPromise()) as Course[];
    }
    console.log(this.courses);
    return this.courses;
  }

  getCoursebyId(id:string): Observable<Course> {
      return this.http.get(`${this.API_URL}/courses/${id}`) as Observable<Course>;
  }
}
