import { Component, OnInit } from '@angular/core';
import { Course } from 'src/app/models/course';
import { CoursesService } from 'src/app/shared/services/courses.service';

@Component({
    selector: 'app-elearning-banner',
    templateUrl: './elearning-banner.component.html',
    styleUrls: ['./elearning-banner.component.scss']
})
export class ElearningBannerComponent implements OnInit {
    courses: Course[] = [];

    constructor(private courseService: CoursesService) { }

    async ngOnInit(): Promise<void> {
        this.courses = await this.courseService.getCourses();
    }

    bgImage = [
        {
            img: 'assets/img/gray-bg.jpg'
        }
    ]

}
