import { Component, OnInit } from '@angular/core';
import { Course } from 'src/app/models/course';
import { CoursesService } from 'src/app/shared/services/courses.service';

@Component({
  selector: 'app-vendor-courses',
  templateUrl: './vendor-courses.component.html',
  styleUrls: ['./vendor-courses.component.scss']
})
export class VendorCoursesComponent implements OnInit {

    courses: Course[] = [];

    constructor(private courseService: CoursesService) { }

    async ngOnInit(): Promise<void> {
        this.courses = await this.courseService.getCourses();
    }

}
