import { Component, OnInit } from '@angular/core';
import { Course } from 'src/app/models/course';
import { CoursesService } from 'src/app/shared/services/courses.service';

@Component({
  selector: 'app-courses-style-one',
  templateUrl: './courses-style-one.component.html',
  styleUrls: ['./courses-style-one.component.scss']
})
export class CoursesStyleOneComponent implements OnInit {

    courses: Course[] = [];

    constructor(private courseService: CoursesService) { }

    async ngOnInit(): Promise<void> {
        this.courses = await this.courseService.getCourses();
    }


}
